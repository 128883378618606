import React from "react";

const JsonLd = ({ data }) => {
  if (data) {
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      />
    );
  }
  return null;
};

export default JsonLd;
