import React, { useEffect, useMemo, useReducer, useState } from "react";
import { graphql } from "gatsby";
import Cookies from "js-cookie";
import Loadable from "@loadable/component";
import flussiData from "../../data/prodotti/index.json";
import {
  stepCookies,
  offerteMapping,
  flussiInit,
  elencoProdottiBreadcrumb,
  fornituraCategoriaMapping,
} from "../../utility/config-flussi-switch-in";
import withPreview from "../../utility/with-preview";
import { dataLayerPush, dataLayerStr2Obj } from "../../utility/dataLayerUtils";
import JsonLd from "../elements/json-ld";

const FlussoModal = Loadable(() => import("../flussi-switch-in/flusso-modal"));

const flussoReducer = (state, action) => {
  const { type, payload } = action;
  //console.log("action: ", action);

  switch (type) {
    case "START_FLUSSO": {
      const {
        flussoName,
        flussoConfig,
        location,
        codCoupon,
        utmParams,
        codicePromo,
        stepProcessiInself,
        fornituraProcessiInself,
        clienteProcessiInself,
      } = payload;
      //console.log("start flusso: ", flussoName, flussoConfig);

      const initFunc = flussiInit[flussoName];
      const initState = initFunc(location, flussoConfig);
      return {
        flussoName,
        nomeFlusso: flussoConfig.nome,
        utmParams,
        flussoConfig,
        data: {},
        cookies: {
          swPaginaPartenza: encodeURIComponent(location.href),
          switchinProdotto: flussoConfig.codice,
          switchinOfferta: flussoConfig.nome,
          switchinNomeProdotto: flussoConfig.nome,
          switchinTipoCliente: "privato",
          ecommerce: "y",
          codiceAmico: codCoupon,
          utmParamCookie: utmParams
            ? new URLSearchParams(Object.entries(utmParams)).toString()
            : "",
          switchinCodicePromo: codicePromo,
        },
        stepFlusso: stepProcessiInself,
        fromProcessiInself: stepProcessiInself > 0 ? true : false,
        fornituraProcessiInself: fornituraProcessiInself,
        clienteProcessiInself: clienteProcessiInself,
        ...initState,
      };
    }
    case "START_FLUSSO_ENERGIA_PLUS": {
      return {
        ...state,
        data: {
          fornitura: "dual",
          bolletta: "email",
          startFornitura: "dual",
          startBolletta: "email",
        },
      };
    }
    case "PREV": {
      // Salto lo step "tariffa-luce" se ho selezionato "gas"
      const prevStep = state.listaStep[state.stepFlusso - 1];
      if (prevStep === "tariffa-luce" && state.data.fornitura === "gas") {
        return {
          ...state,
          stepFlusso: Math.min(state.stepFlusso - 2, state.listaStep.length - 1),
        };
      }
      // Salto lo step "accedi-spazio-clienti" se provengo dal flusso di processi inself e sono cliente
      if (
        prevStep === "accedi-spazio-clienti" &&
        state.fromProcessiInself &&
        state.clienteProcessiInself === "cliente"
      ) {
        return {
          ...state,
          stepFlusso: Math.min(state.stepFlusso - 2, state.listaStep.length - 1),
        };
      }
      // Salto lo step "upselling-plus" se ho selezionato "dual" o sono già cliente
      if (
        prevStep === "upselling-plus" &&
        (state.data.fornitura === "dual" || state.data?.["scelta-cliente"] === "cliente")
      ) {
        return {
          ...state,
          stepFlusso: Math.min(state.stepFlusso - 2, state.listaStep.length - 1),
        };
      }
      // Salto lo step "fornitura-bolletta" e azzero eventuali valori se ho selezionato "nuovo cliente"
      if (prevStep === "fornitura-bolletta" && state.data?.["scelta-cliente"] === "nuovo") {
        return {
          ...state,
          stepFlusso: Math.min(state.stepFlusso - 2, state.listaStep.length - 1),
        };
      }
      // Salto lo step "tipologia-offerta-bolletta" e azzero eventuali valori se ho selezionato "gas"
      if (
        prevStep === "tipologia-offerta-bolletta" &&
        (state.data.fornitura === "gas" || state.data.fornitura === "dual")
      ) {
        return {
          ...state,
          stepFlusso: Math.min(state.stepFlusso - 2, state.listaStep.length - 1),
        };
      }
      return {
        ...state,
        stepFlusso: Math.max(0, state.stepFlusso - 1),
      };
    }
    case "NEXT": {
      // Salto lo step "tariffa-luce" e azzero eventuali valori se ho selezionato "gas"
      const nextStep = state.listaStep[state.stepFlusso + 1];
      if (nextStep === "tariffa-luce" && state.data.fornitura === "gas") {
        const newData = { ...state.data };
        delete newData["tariffa-luce"];
        return {
          ...state,
          data: {
            ...newData,
          },
          cookies: {
            ...state.cookies,
            switchinNomeProdotto: state.flussoConfig.nome,
          },
          stepFlusso: Math.min(state.stepFlusso + 2, state.listaStep.length - 1),
          endFlusso: !nextStep,
        };
      }
      // Salto lo step "accedi-spazio-clienti" se provengo dal flusso di processi inself e sono cliente
      if (
        nextStep === "accedi-spazio-clienti" &&
        state.fromProcessiInself &&
        state.clienteProcessiInself === "cliente"
      ) {
        const newData = { ...state.data };
        delete newData["accedi-spazio-clienti"];
        return {
          ...state,
          data: {
            ...newData,
          },
          cookies: {
            ...state.cookies,
            switchinNomeProdotto: state.flussoConfig.nome,
          },
          stepFlusso: Math.min(state.stepFlusso + 2, state.listaStep.length - 1),
          endFlusso: !nextStep,
        };
      }
      // Apro l'ecommerce se il prossimo step è "accedi-spazio-clienti", provengo dal flusso di processi inself e non sono cliente
      if (
        nextStep === "accedi-spazio-clienti" &&
        state.fromProcessiInself &&
        state.clienteProcessiInself === "nuovo"
      ) {
        window.open("/ecommerce-attivazione-offerta/", "_blank");
        return {
          ...state,
          stepFlusso: -1,
        };
      }
      // Salto lo step "upselling-plus" se ho selezionato "dual" o sono già cliente
      if (
        nextStep === "upselling-plus" &&
        (state.data.fornitura === "dual" || state.data?.["scelta-cliente"] === "cliente")
      ) {
        const newData = { ...state.data };
        delete newData["upselling-plus"];
        return {
          ...state,
          data: {
            ...newData,
          },
          cookies: {
            ...state.cookies,
            switchinNomeProdotto: state.flussoConfig.nome,
          },
          stepFlusso: Math.min(state.stepFlusso + 2, state.listaStep.length - 1),
          endFlusso: !nextStep,
        };
      }
      // Salto lo step "fornitura-bolletta" e azzero eventuali valori se ho selezionato "nuovo cliente"
      if (nextStep === "fornitura-bolletta" && state.data?.["scelta-cliente"] === "nuovo") {
        const newData = { ...state.data };
        delete newData["fornitura-bolletta"];
        return {
          ...state,
          data: {
            ...newData,
          },
          cookies: {
            ...state.cookies,
            switchinNomeProdotto: state.flussoConfig.nome,
          },
          stepFlusso: Math.min(state.stepFlusso + 2, state.listaStep.length - 1),
          endFlusso: !nextStep,
        };
      }
      // Salto lo step "tipologia-offerta-bolletta" e azzero eventuali valori se ho selezionato "gas"
      if (
        nextStep === "tipologia-offerta-bolletta" &&
        (state.data.fornitura === "gas" || state.data.fornitura === "dual")
      ) {
        const newData = { ...state.data };
        delete newData["tipologia-offerta-bolletta"];
        return {
          ...state,
          data: {
            ...newData,
          },
          cookies: {
            ...state.cookies,
            switchinNomeProdotto: state.flussoConfig.nome,
          },
          stepFlusso: Math.min(state.stepFlusso + 2, state.listaStep.length - 1),
          endFlusso: !nextStep,
        };
      }
      return {
        ...state,
        stepFlusso: Math.min(state.stepFlusso + 1, state.listaStep.length - 1),
        endFlusso: !nextStep,
      };
    }
    case "CANCEL": {
      return {
        ...state,
        stepFlusso: -1,
      };
    }
    case "SET_PARAMETER": {
      const { key, value } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          [key]: value,
        },
      };
    }
    case "PUSH_DATA": {
      const { fornitura, flussoConfig } = payload;
      dataLayerPush({
        event: "addToCart",
        ecommerce: {
          currencyCode: "EUR",
          add: {
            products: [
              {
                id: flussoConfig.codice,
                name: elencoProdottiBreadcrumb.get(flussoConfig.nome),
                brand: "",
                category: flussoConfig.tipologia,
                variant: fornituraCategoriaMapping[fornitura],
                quantity: 1,
              },
            ],
          },
        },
      });
      return state;
    }
    case "PUSH_ECOMMERCE": {
      // Tracciamenti PRE-STEP ecommerce
      let scelta = "";
      let sceltaBol = "";
      let currStep = state.listaStep[state.stepFlusso - 1];
      console.log(currStep);
      console.log(state);

      if (currStep === "tipologia-offerta-bolletta") {
        if (state.data?.["tipologia-offerta"] === "SDD + BOL") {
          scelta = "SDD + BOL";
        } else {
          scelta = "NO SDD - NO BOL";
        }
      }

      if (
        currStep === "fornitura" ||
        currStep === "welcome-step" ||
        currStep === "fornitura-plus" ||
        currStep === "fornitura-bolletta"
      ) {
        scelta = state.data.fornitura;
      }

      if (currStep === "fornitura-plus") {
        sceltaBol = state.data.bolletta;
      }

      if (currStep === "tariffa-luce") {
        scelta = state.data?.["tariffa-luce"];
      }

      if (currStep === "tariffa-luce" && state.data.fornitura === "gas") {
        scelta = "gas";
      }

      if (currStep === "pacchetto-assicurativo") {
        scelta = state.data?.["pacchetto-assicurativo"];
      }

      if (
        currStep === "accedi-spazio-clienti" ||
        currStep === "accedi-spazio-clienti-plus" ||
        currStep === "upselling-plus" ||
        currStep === "accedi-spazio-clienti-bolletta"
      ) {
        if (state.data?.["scelta-cliente"] === "nuovo") {
          scelta = "Nuovo cliente";
        } else {
          scelta = "Già cliente";
        }
        // scelta = "Già " + state.data?.["scelta-cliente"];
      }

      if (sceltaBol !== "") {
        dataLayerPush({
          event: "selectContent",
          content_type: "prestep_casa",
          prestep_progress: state.stepFlusso,
          nome_prodotto: state.flussoConfig.codice,
          prestep_scelta: scelta,
          scelta_invio_bolletta: sceltaBol,
        });
      } else {
        dataLayerPush({
          event: "selectContent",
          content_type: "prestep_casa",
          prestep_progress: state.stepFlusso,
          nome_prodotto: state.flussoConfig.codice,
          prestep_scelta: scelta,
        });
      }

      return state;
    }
    default:
      return state;
  }
};

const UTM_PARAMS = [
  "endtoend",
  "subscription",
  "tduid",
  "utm_medium",
  "utm_source",
  "utm_campaign",
  "utm_content",
];

const generateLdData = (infoprodotti) => {
  // lowPrice: valore massimo di componentePrezzoFisso
  const prezzi = infoprodotti.map((prod) =>
    parseFloat(prod.componentePrezzoFisso.replace(",", "."))
  );
  const maxIndex = prezzi.indexOf(Math.max.apply(null, prezzi));
  return infoprodotti[maxIndex]
    ? {
        "@context": "https://schema.org",
        "@type": "Product",
        name: infoprodotti[maxIndex].nomeProdottoWeb,
        offers: {
          "@type": "AggregateOffer",
          highPrice: "",
          lowPrice: infoprodotti[maxIndex].componentePrezzoFisso.replace(",", "."),
          offerCount: "",
          priceCurrency: "EUR",
          offers: [],
        },
      }
    : null;
};

const FlussoSwitchIn = ({ data, location }) => {
  const { nomeProdottoLiferay, infoprodotti, structuredData, gaEvent } = data;

  const flussiMap = useMemo(() => {
    return nomeProdottoLiferay?.reduce((res, item) => {
      if (item.content?.nomeFlusso?.value?.[0]) {
        res.set(item.value, item.content.nomeFlusso.value[0]);
      }
      return res;
    }, new Map());
  }, [nomeProdottoLiferay]);

  const [loadedParams, setLoadedParams] = useState();
  const [coupon, setCoupon] = useState();
  const [utmObj, setUtmObj] = useState();
  const [codicePromo, setCodicePromo] = useState();

  useEffect(() => {
    if (!loadedParams) {
      const params = new URLSearchParams(location?.search);
      const utmObj = UTM_PARAMS.reduce((res, name) => {
        const value = params.get(name);
        return value ? { ...res, [name]: value } : res;
      }, {});
      setUtmObj(utmObj);
      const codCoupon = params.get("codCoupon");
      setCoupon(codCoupon);
      const id = params.get("id");
      const endtoend = params.get("endtoend");
      const subscription = params.get("subscription");
      if (id || endtoend) {
        const codicePromo =
          subscription && endtoend ? `${endtoend}_${subscription}` : endtoend ? endtoend : id;
        setCodicePromo(codicePromo);
      }
      setLoadedParams(true);
    }
  }, [location, loadedParams]);

  const [state, dispatch] = useReducer(flussoReducer, { data: {}, stepFlusso: -1 });
  const {
    data: flussoData,
    cookies: flussoCookies,
    endFlusso,
    utmParams,
    listaStep,
    stepFlusso,
  } = state;
  const currentStep = listaStep?.[stepFlusso];

  useEffect(() => {
    const listener = (e) => {
      const {
        productName,
        stepProcessiInself = 0,
        ecommerceProcessiInself = false,
        fornituraProcessiInself,
        clienteProcessiInself,
      } = e.detail || {};
      const flussoConfig = flussiData.find((item) => item.nome === productName);
      const flussoName = flussiMap.get(productName);
      if (flussoConfig && flussoName && ecommerceProcessiInself) {
        window.open("/ecommerce-attivazione-offerta/", "_blank");
      }
      if (flussoConfig && flussoName && !ecommerceProcessiInself) {
        dispatch({
          type: "START_FLUSSO",
          payload: {
            productName,
            flussoName,
            flussoConfig,
            location,
            codCoupon: coupon,
            utmParams: utmObj,
            codicePromo,
            stepProcessiInself,
            fornituraProcessiInself,
            clienteProcessiInself,
          },
        });
        //GA4 AddToCart
        if (gaEvent) {
          dataLayerPush(dataLayerStr2Obj('{"ecommerce":null}'));
          dataLayerPush(dataLayerStr2Obj(gaEvent.value));
        }
        //
      }
    };
    document.addEventListener("start-flusso-switch-in", listener);
    return () => document.removeEventListener("start-flusso-switch-in", listener);
  }, [flussiMap, location, coupon, utmObj, codicePromo, gaEvent]);

  useEffect(() => {
    if (flussoData && flussoCookies) {
      const offerta = flussoCookies?.switchinOfferta;
      const mapping = offerteMapping[offerta];
      Object.entries(flussoCookies)
        .map(([key, value]) => ({
          cookie: key,
          value,
        }))
        .concat(
          Object.entries(flussoData).map(([key, value]) => ({
            cookie: stepCookies[key],
            value: key === "tariffa-luce" && mapping ? mapping[value] : value,
          }))
        )
        .concat(
          offerta === "energiaplus" && flussoData?.fornitura && flussoData?.bolletta
            ? [
                {
                  cookie: "switchinProdotto",
                  value: mapping[flussoData.fornitura][flussoData.bolletta],
                },
              ]
            : []
        )
        .concat(
          offerta === "abbassalabolletta2024"
            ? [
                {
                  cookie: "switchinCommodity",
                  value: "gas",
                },
              ]
            : []
        )
        .forEach(({ cookie, value }) => {
          console.info("Update flusso cookie: ", cookie, value);
          if (value) {
            Cookies.set(cookie, value);
          } else {
            Cookies.remove(cookie);
          }
        });
    }
  }, [flussoData, flussoCookies]);

  useEffect(() => {
    if (endFlusso || currentStep === "spinner") {
      const paramEntries = Object.entries(utmParams);
      window.location.href = `/ecommerce-attivazione-offerta/${
        paramEntries.length ? "?" + new URLSearchParams(paramEntries).toString() : ""
      }`;
    }
  }, [endFlusso, currentStep, utmParams]);

  return (
    <section className="flusso-switch-in">
      {state.stepFlusso >= 0 && (
        <FlussoModal state={state} dispatch={dispatch} location={location} />
      )}
      {structuredData?.value && <JsonLd data={generateLdData(infoprodotti)} />}
    </section>
  );
};

export default withPreview(FlussoSwitchIn);
export const fragment = graphql`
  fragment JskFlussoSwitchInFragment on LiferayJskFlussoSwitchIn {
    liferayFields {
      siteId
      articleId
    }
    infoprodotti {
      componentePrezzoFisso
      nomeProdottoWeb
    }
    nomeProdottoLiferay {
      value
      content {
        nomeFlusso {
          value
        }
      }
    }
    structuredData {
      value
    }
    gaEvent {
      value
    }
  }
`;
